import { articleCodeMP } from "../api/api"

const ACTION = {
    GET_ARTICLE: 'GET-ARTICLE',
    SET_CODEMP: "SET-CODEMP"
}

const init = {
    response: [],
    codeMP: ''
}

const getArticleCreator = (data) => ({ type: ACTION.GET_ARTICLE, data })
const setCodeMPCreator = (codeMP) => ({ type: ACTION.SET_CODEMP, codeMP })

const ArticleCodeMPReduser = (state = init, action) => {
    switch (action.type) {
        case ACTION.SET_CODEMP:
            return {
                ...state,
                codeMP: action.codeMP
            }

        case ACTION.GET_ARTICLE:
            return {
                ...state,
                codeMP: "",
                response: action.data
            }

        default: return state
    }
}

export const setCodeMP = (codeMP) => (dispatch) => {
    dispatch(setCodeMPCreator(codeMP))
}
export const getArticleCodeMP = (codeMP) => (dispatch) => {
    articleCodeMP.getArticle(codeMP)
        .then(data => {
            dispatch(getArticleCreator(data))
        })
        .catch(err => { console.error(err) })
}

export default ArticleCodeMPReduser