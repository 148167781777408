import React from "react";
import s from "./ArticleDownload.module.css";
import GooglePlay from "../../common/assets/Google-Play.png";
import AppStore from "../../common/assets/AppStore.png";
import Rustore from "../../common/assets/Rustore.png";
import AppGallery from "../../common/assets/AppGallery.png";
import KEYS from "../../common/assets/BGj8zfMNIDE.jpg";

const ArticleDownload = () =>{
return(
    <div className={s.mainContainer}>
        <div className={s.containerInfo}>
            <div className={s.article}>
            <h4>Get more comfort and security in your smartphone</h4>
            <div className={s.containerImgDownload}>
            <a href="https://play.google.com/store/apps/details?id=com.smd.ip_smd&hl=ru&gl=US" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={GooglePlay} alt="Google Play" />
                </a>
                <a href="https://apps.apple.com/ru/app/d-keys/id1502365079" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={AppStore} alt="App Store" />
                </a>
                <a href="https://appgallery.huawei.com/app/C106402815" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={AppGallery} alt="App Store" />
                </a>
                <a href="https://www.rustore.ru/catalog/app/com.smd.ip_smd" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={Rustore} alt="App Store" />
                </a>
            </div>
            {/* 
            <h4>D-KEYS</h4>
            <p>Скачать мобильное приложение D-KEYS вы можете по ссылкам:</p>
            <div className={s.containerImgDownload}>
            <a href="https://play.google.com/store/apps/details?id=com.smd.ip_smd&hl=ru&gl=US" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={GooglePlay} alt="Google Play" />
                </a>
                <a href="https://apps.apple.com/ru/app/d-keys/id1502365079" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={AppStore} alt="App Store" />
                </a>
            </div>
            <p>Если вам не удалось скачать приложение по этой ссылке:</p>
            <ul className={s.ulfordownload}>
                <li>зайдите в магазин Google Play / AppStore</li>
                <li>введите название D-KEYS и нажмите «поиск»</li>
            </ul>
            <p>Также вы можете обратиться в службу поддержки платформы по телефону:</p>
            <a  className={s.linkphone} href="tel:+79605400020">+7 (960) 540-00-20</a>
            <p>Либо написать нам в любой из мессенджеров:</p>
            <ul className={s.ulforsupport}>
                <li><a  className={s.linkSupport}  href="https://t.me/d_key_help" target="_blank">Telegram</a></li>
                <li><a className={s.linkSupport}  href="https://api.whatsapp.com/send/?phone=%2B79605400020&text&type=phone_number&app_absent=0" target="_blank">WhatsApp</a></li>
                <li><a className={s.linkSupport}  href="https://viber.click/+79605400020" target="_blank">Viber</a></li>
            </ul>
            <div className={s.containerImgDownload}>
            <img className={`${s.infoimg1}`} src={KEYS} alt="KEYS"></img></div>
           */}  </div>
        </div>
    </div>
)
}
export default ArticleDownload;