import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../../common/NotFound/NotFound';
import s from './ArticleCodeMP.module.css'
import robort from "../../common/assets/robot.gif"

const ArticleCodeMP = (props) => {
    const { id } = useParams();

    if (!id) {
        return (
            <div className={s.containerArticle}>
                <h1>Эта страница предназначена для отображения информационной статьи о системе умного домофона.</h1>

            </div>

        )
    }
    if (props.state.articleCodeMP.response.length != 0) {
        if (props.state.articleCodeMP.response.DATA) {
            return (

                <div className={s.containerArticle}>
                    {props.state.articleCodeMP.response.DATA.map(function (item) {
                        return (
                            <div>
                                <h1 className={s.headerArticle}>{item.header}</h1>
                                {item.image ? <img className={s.img} src={item.image}></img> : null}
                                <p className={s.paragrafArticle}>{item.text}</p>
                            </div>
                        )
                    })}

                </div>
            )
        } else if (props.state.articleCodeMP.response['message'] === "Опаньки, какие-то не предвиденные ошибки :(" || props.state.articleCodeMP.response['message'] === "Error #13.0.2 Неверный запрос." || props.state.articleCodeMP.response['message'] === "Error #13.5.1 Ошибка подключения, попробуйте снова" || props.state.articleCodeMP.response['message'] === "Не получилось подключится к серверу") {
            return (
                <div className={s.containerArticle}>
                    <img className={s.robot} src={robort}></img>
                    <h1 className={s.errorheader}>Упс, что-то пошло не так, попробуйте снова</h1>
                </div>

            )
        }
        else if (props.state.articleCodeMP.response['message'] === "Error #13.0.0 Недостаточно данных для запроса." || props.state.articleCodeMP.response['message'] === "Error #13.0.1 Недостаточно данных для запроса." || props.state.articleCodeMP.response['message'] === "Error #13.5.2 Данных нет") {
            return (
                <div className={s.containerArticle}>
                    <NotFound />
                </div>

            )
        }
    } else {
        return (
            <div className={s.containerArticle}>
                <NotFound />
            </div>

        )
    }
}
export default ArticleCodeMP